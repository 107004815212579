<template>
    <RightSideBar @close="$emit('close','restore')" button-class="bg-dynamicBackBtn text-white" :submit="'Yes, Confirm'" :cancel="'No, Cancel'" :loading="isLoading" @submit="submit">
        <template v-slot:title>
            <div class="text-darkPurple flex gap-5 items-center">
            <div class=""  @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> Restore {{currentTab.toLowerCase().includes('folders') ? 'Folder' : 'File'}}
            </div>
        </template>
        <div class="flex flex-col justify-center items-center gap-2 mt-20">
            <img :src="require('@/assets/images/download-box.png')" alt="not_found">
            <div class="flex justify-center items-center text-center text-jet font-semibold">Confirm you want to restore this {{currentTab.toLowerCase().includes('folders') ? 'Folder' : 'File'}}</div>
        <div class="flex justify-center items-center text-center px-10 text-sm">Your {{currentTab.toLowerCase().includes('folders') ? 'folder' : 'file'}} will be restored back once you confirm.</div>
        </div>
    </RightSideBar>
</template>

<script>
  import RightSideBar from "@/components/RightSideBar";

  export default {
    name: "Restore",
    components: {
      RightSideBar
    },
    props: {
        id: {
        type: String,
        default: ''
      },
      currentTab: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        isLoading: false,
      };
    },
    methods: {
        closeSidebar() {
            this.$emit('close','restore');
        },
        async submit() {
            if(this.currentTab.toLowerCase().includes('folders')){
                await this.restoreAFolder()
            }else{
               await this.restoreAFile()
            }
        },
        async restoreAFolder(){
            this.isLoading = true;
            try {
                await this.$_restoreAFolder(this.id);
                this.$emit('refresh');
                this.closeSidebar();
                this.isLoading = false;

                return this.$toasted.success("Folder restored successfully.",{duration: 6000});
            } catch (error) {
                this.isLoading = false;
                return this.$toasted.error("Error restoring a folder", {duration: 6000});
            }
        },
        async restoreAFile(){
            this.isLoading = true;
            try {
                await this.$_restoreAFile(this.id)
                this.$emit('refresh');
                this.closeSidebar();
                this.isLoading = false;

                return this.$toasted.success("File restored successfully.",{duration: 6000});
            } catch (error) {
                this.isLoading = false;
                return this.$toasted.error("Error restoring a file", {duration: 6000});
            }
        }
    }
  };
  </script>

  <style scoped>
  </style>
